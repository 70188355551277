.App-Copyright {
	padding-top: 15px;	
	text-align: center;
	font-family: 'Poiret One', cursive;
	font-weight: 600;

	a {
		text-transform: uppercase;
		color: $white;
	}
}