.App-Navigation {
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Roboto Condensed', sans-serif;

    .dropdown {
        .dropdown-menu {
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            -ms-transition: all 0.5s;
            -o-transition: all 0.5s;
            transition: all 0.5s;

            max-height: 0;
            display: block;
            overflow: hidden;
            opacity: 0;
            padding: 0;
        }

        &.show {
            .dropdown-menu {
                max-height: 300px;
                opacity: 1;
            }
        }
    }
}

// Phone
@media only screen and (max-width: 992px) {
    .App-Navigation {
        .navbar {
            background-color: $blue-two;
            color: $white;

            .navbar-brand {
                img {
                    width: 250px;
                    padding-left: 16px;
                }
            }

            .navbar-toggler {
                border: none;

                .navbar-toggler-icon {
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
                }
            }

            .navbar-nav {
                .nav-link {
                    color: $white;

                    &.active {
                        color: $green;
                    }
                }

                .dropdown {
                    .dropdown-menu {
                        text-align: center;
                        border: none;
                        background-color: $blue-two;

                        .dropdown-item {
                            color: $white;

                            &.active {
                                color: $green;
                                background-color: $blue-two;
                            }
                        }
                    }
                }

                .phrase {
                    font-family: 'Tangerine', cursive;
                    font-size: 30px;
                    text-transform: lowercase;
                    margin-top: 12px;
                }
            }
        }
    }
}

// Desktop
@media only screen and (min-width: 992px) {
    .App-Navigation {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .navbar-brand {
            display: none;
        }

        .navbar-nav {
            margin-top: 15px;

            a.nav-link {
                margin: 0 30px;
                padding: 0;
                color: $blue;
                border-bottom: 2px solid $white;

                &.active,
                &:hover {
                    color: $blue-light;
                    border-bottom: 2px solid $green;
                }
            }

            .phrase {
                display: none;
            }

            .dropdown {
                .dropdown-menu {
                    .dropdown-item {
                        &:active,
                        &.active {
                            color: $green;
                            background-color: $white;
                        }
                    }
                }
            }
        }
    }
}
