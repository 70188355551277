.App-Header img {
	padding: 24px;
	max-width: 30%;
}

@media only screen and (max-width: 768px) {
	.App-Header {
		display: none;
	}
}
