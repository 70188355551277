@import '~bootstrap/scss/bootstrap';
@import '@brainhubeu/react-carousel/lib/style.css';

// Google Fonts
@import url(//fonts.googleapis.com/css?family=Roboto+Slab:400,700,300|Roboto:400,700,300|Open+Sans:300italic,400italic,600italic,700italic,800italic,400,800,700,600,300);
@import url(//fonts.googleapis.com/css?family=Roboto+Condensed:400,300,300italic,400italic,700,700italic);
@import url(https://fonts.googleapis.com/css?family=Tangerine:400,700);
@import url(https://fonts.googleapis.com/css?family=Poiret+One);

@import 'styles/variables.scss';

@import 'styles/components/copyright.scss';
@import 'styles/components/footer.scss';
@import 'styles/components/header.scss';
@import 'styles/components/image_starkey.scss';
@import 'styles/components/navigation.scss';
@import 'styles/components/page_title.scss';
@import 'styles/components/slides.scss';
@import 'styles/components/stats.scss';
@import 'styles/components/service.scss';
@import 'styles/pages/contact.scss';
@import 'styles/pages/dr_juan.scss';
@import 'styles/pages/dra_alina.scss';

.App {
  text-align: center;

  .text-block {
    padding: 25px;
    text-align: justify;
    font-family: "Roboto Condensed", sans-serif;
  }
}
