.App-Footer {
	padding: 60px 15px 0 15px;
	background-color: $blue;
	color: $gray;
	text-align: left;
	font-family: 'Roboto Condensed', sans-serif;

	.column-container {
		margin-bottom: 40px;

		h4 {
			font-family: 'Poiret One', cursive;
			color: $white;
			font-weight: 600;
			margin-bottom: 20px;

			&.brand {
				text-transform: uppercase;
			}
		}

		p {
			text-align: justify;
		}

		a {
			color: $gray;
		}

		.align-right {
			text-align: right;
		}


		.fb-page-container {
			display: flex;
			justify-content: center;	
		}

		.item-container {
			display: flex;
			margin-left: 10px;

			svg {
				margin-top: 4px;
				color: $white;
			}

			p {
				margin-left	: 10px;
			}
		}
	}
}
