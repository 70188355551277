.App-Dra-Alina {
	.image-alina {
		height: 300px;
		background-image: url("../../images/dra-alina.jpg");
  		background-size: auto 100%;
  		background-position: center;
	}
}

// Desktop
@media only screen and (min-width: 992px) {
    .App-Dra-Alina {
		.image-alina {
			height: 666px;
		}
    }
}

// Tablet
@media only screen and (max-width: 1024px) {
    .App-Dra-Alina {
    	.image-alina-container {
    		margin: auto;
    	}
    }
}
