// Colors
$white: #ffffff;
$dark-white: darken($white, 10);
$black: #000000;
$blue: #0B2457;
$blue-light: lighten($blue, 20);
$blue-dark: darken($blue, 20);
$blue-two: #3051A2;
$green: #96B74C;
$gray: #828282;
