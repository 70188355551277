.App-PageTitle {
	padding: 50px;
	background-color: $green;
	color: $blue;
	font-family: "Poiret One", cursive;

	h1 {
		font-weight: 700;
	}
}
