.App-Dr-Juan {
	.image-juan {
		height: 380px;
		background-image: url("../../images/dr-juan.jpg");
  		background-size: cover;
  		background-position: center;
	}
}

// Desktop
@media only screen and (min-width: 992px) {
    .App-Dr-Juan {
		.image-juan {
			height: 490px;
		}
    }
}

// Tablet
@media only screen and (max-width: 1024px) {
    .App-Dr-Juan {
    	.image-juan-container {
    		margin: auto;
    	}
    }
}
