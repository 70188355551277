.App-Slides {
    .slide {
        width: 100%;
        height: 450px;
        background-size: cover;

        p {
            font-family: 'Poiret One', cursive;
            font-weight: 600;
            font-size: 30px;
            color: $white;
            position: absolute;
            left: 30px;
            bottom: 30px;
            text-align: left;
            text-shadow: 1px 1px #000000;
        }

        &.slide-one {
            background-image: url("../../images/slide-1.jpg");
        }

        &.slide-two {
            background-image: url("../../images/slide-2.jpg");
        }

        &.slide-three {
            background-image: url("../../images/slide-3.jpg");
            background-position-x: 50%;
        }

        &.slide-four {
            background-image: url("../../images/slide-4.jpg");
        }
    }
}

// Desktop
@media only screen and (min-width: 992px) {
    .App-Slides {
        margin-top: 20px;

        .slide {
            height: 680px;

            p {
                font-size: 60px;
                left: 80px;
                bottom: 60px;
            }
        }
    }
}
