.App-Stats {
	margin-top: 60px;
	color: $blue-light;
	font-family: 'Poiret One', cursive;
	font-weight: 600;

	.item-container {
		margin-bottom: 35px;

		svg {
			height: 85px;
			width: 85px;
			border: solid 2px $blue-light;
			border-radius: 50%;
			padding: 20px;
			margin-bottom: 15px;
		}

		.stats-number {
			font-size: 35px;
			margin-bottom: 0;
		}

		.stats-info {
			font-size: 20px;
		}
	}
}

// Desktop
@media only screen and (min-width: 992px) {
	.App-Stats {
	    margin-top: 80px; 

		.item-container {
			svg {
				margin-bottom: 25px;
			}

			.stats-number {
				margin-bottom: 10px;
			}
		}
	}
}
