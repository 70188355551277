.App-Contact {
	.title-container {
		font-family: "Poiret One", cursive;
		color: $green;
		padding: 30px;
		text-align: center;

		h3 {
			font-weight: 800;
		}
	}

	textarea,
	input {
		width: 100%;
		padding: 10px 15px;
		font-family: 'Roboto Condensed', sans-serif;
		margin-bottom: 20px;

		&[type="submit"] {
			background-color: $green;
			border: solid 1px $green;
			color: $white;
			width: 100px;
			outline: none;

			&:hover {
				border: solid 1px $green;
				color: $green;
				background-color: $white;
			}
		}
	}


}