.App-Service {
    .text-block {
        h1 {
            font-family: "Helvetica Neue", "Arial", sans-serif;
            font-size: 25px;
            font-weight: 500;
            margin-bottom: 20px;
        }
    }

    .image {
        height: 380px;
        background-size: cover;
        background-position: center;

        &.headphone {
            height: 360px;
        }
    }

    // Exams
    .image-audiometria {
        background-image: url("../../images/services/audiometria.jpg");
    }

    .image-logoaudiometria {
        background-image: url("../../images/services/logoaudiometria.jpg");
    }

    .image-impedanciometria {
        background-image: url("../../images/services/impedanciometria.jpg");
    }

    .image-tamizaje {
        background-image: url("../../images/services/tamizaje.jpg");
    }

    .image-ocupacional {
        background-image: url("../../images/services/ocupacional.jpg");
    }

    // Headphones
    .image-cic {
        background-image: url("../../images/services/cic.jpg");
    }

    .image-retroauricular {
        background-image: url("../../images/services/retroauricular.jpg");
    }

    .image-intracanal {
        background-image: url("../../images/services/intracanal.jpg");
    }

    .image-ric {
        background-image: url("../../images/services/ric.jpg");
    }

    // Protection
    .image-ruido {
        background-image: url("../../images/services/ruido.jpg");
    }

    .image-agua {
        background-image: url("../../images/services/agua.jpg");
    }

    .image-musicos {
        background-image: url("../../images/services/musicos.jpg");
    }
}

// Desktop
@media only screen and (min-width: 992px) {
    .App-Service {
        .text-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }

        .image.headphone {
            height: 570px;
        }
    }
}

// Tablet
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .App-Service {
        .text-block {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }

        .image.headphone {
            height: 480px;
            background-size: 100%;
        }
    }
}
